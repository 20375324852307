import React, { useState, useEffect, ChangeEvent, FormEvent } from 'react';
import ReactDOM from "react-dom";
import './App_table.css';
import { std } from 'mathjs';
interface Person {
  id: number;
  name: string;
  date: string;
  a: number;
  b: number;
  c: number;
  d: number;
  e: number;
  f: number;
  c1: number;
  c2: number;
  lotno: string;
  remarks: string;
}
interface TableNames {
  name: string;
  type: string;
  matcode: string;
  batch: string;
  expiry: string;
  rangetable: string;
  cvrule: string;
  tablecreator: string;
  stamp: string;
  stampauthor: string;
  stamptext: string;
  stamptime: string;
  approvecount: number;
}

let indexesLeft: number[] = [];
let indexesRight: number[] = [];
let firstLoadTables: boolean = false;
let cvrule:string;
const Table: React.FC = () => {
  // const [tableData, setTableData] = useState<Person[]>([
  //   { id: 1, name: 'Alice', date: '1990-01-01', a: 1, b: 3, c: 2, d: 1, e: 6 },
  //   { id: 2, name: 'Bob', date: '1995-05-08', a: 2, b: 2, c: 3, d: 2, e: 24 },
  //   { id: 3, name: 'Carl', date: '1996-06-09', a: 2, b: 2, c: 3, d: 2, e: 24 },
  //   { id: 4, name: 'Devid', date: '1997-07-10', a: 2, b: 2, c: 3, d: 2, e: 24 },
  // ]);
  const [tableData, setTableData] = useState<Person[]>([]);
  const [tableDataSelected, setTableDataSelected] = useState<Person[]>([]);

  const [dataLoaded, setDataLoaded] = useState(false);
  const [dataLoadedFirst, setDataLoadedFirst] = useState(false);

  const [loggedIn, setLoggedIn] = useState(false);
  const [startLoggedIn, setStartLoggedIn] = useState(false);
  const [loggedInError, setLoggedInError] = useState(false);
  const [showBlockLogin, setShowBlockLogin] = useState(true);

  const [hideTableForChange, setHideTableForChange] = useState(true);
  const [hideTableForChangeTrans, setHideTableForChangeTrans] = useState(true);

  const [fileName, setFileName] = useState(''); // Изначальное значение имени файла

  // const [newTablefileName, setNewTableFileName] = useState(''); // Изначальное значение имени файла

  // useEffect(() => {
  //   if (loggedIn) {
  //     const fetchData = async () => {
  //       try {
  //         const response = await fetch('https://ldx.cloudronics.com/api/data', {
  //           method: 'GET',
  //           mode: 'cors',
  //           headers: {'Content-Type': 'application/json',},
  //         });
  //         const data = await response.json();
  //
  //         console.log('Get data successfully')
  //         // console.log(data);
  //         setTableData(data);
  //         setDataLoaded(true);
  //         setTimeout(() => setShowBlockLogin(false), 1000)
  //       } catch (error) {
  //         console.error('Error fetching data:', error);
  //       }
  //     };
  //
  //     const intervalId = setInterval(fetchData, 10000);
  //
  //     return () => clearInterval(intervalId);
  //   }
  // }, [loggedIn]);


  const [tableNames, setTableNames] = useState<TableNames[]>([]);
  useEffect(() => {
    if (loggedIn) {
      const fetchDataT = async () => {
        try {
          const response = await fetch(`https://ldx.cloudronics.com/api/tablenames`, {
            method: 'GET',
            mode: 'cors',
            headers: {'Content-Type': 'application/json'},
          });
          const data = await response.json();

          console.log('Get Table Names');

          // if (fileName.length<3){
          if (firstLoadTables===false){
            // console.error('if (tableNames.length<1){',firstLoadTables,"}}}")
            // вернуть
             setFileName(data[1].name);
           // setFileName('1');
            firstLoadTables=true;
            setTimeout(() => {setShowBlockLogin(false);console.log("showBlockLogin сработал 2000", showBlockLogin)}, 2000);
          }

          setTableNames(data);
          setDataLoaded(true);

          // console.log('dataLoadedFirst',dataLoadedFirst)
          // if (tableNames.length>0){
          //   // console.log('dataLoadedFirst',dataLoadedFirst)
          //   setTimeout(() => setDataLoadedFirst(true), 1000);
          //   console.log('dataLoadedFirsttimeout',dataLoadedFirst)
          //   setTimeout(() => setShowBlockLogin(false), 2000);}
        } catch (error) {
          console.error('Error fetching data:', error);
        }
      };
      fetchDataT();
      setDataLoaded(false);

      const intervalId = setInterval(fetchDataT, 10000);

      return () => clearInterval(intervalId);
    }
  }, [loggedIn]);
  const getDataByName = (name: string) => {
    const selectedTable = tableNames.find(item => item.name === name);
    return selectedTable ? selectedTable : {name:'',type:'new', matcode: '', batch: '', expiry: '', rangetable: '', cvrule: '', tablecreator: '', stamp: '', stampauthor: '', stamptext: '', stamptime: '',approvecount:0 };
  };
  const selectedTable = getDataByName(fileName);

  const [formNewTable, setFormNewTable] = useState<TableNames>({
    name: '', type: 'new', matcode: '', batch: '', expiry: '', rangetable: '', cvrule: '', tablecreator: '',
    stamp: '', stampauthor: '', stamptext: '', stamptime: '', approvecount:0
  });


  const sendTableNamesToServer = async (data: TableNames[]) => {
    console.log('sendTableNamesToServer',data);
    try {
      const response = await fetch(`https://ldx.cloudronics.com/api/tablenames`, {
        method: 'POST',
        headers: {'Content-Type': 'application/json'},
        body: JSON.stringify(data)
      });

      if (!response.ok) {throw new Error('Failed to Table Names data to server');}
      console.log('Sent Table Names successfully');
    } catch (error) {console.error('Error sending Table Names to server:', error);}
  };

  const handleChangeNewTable = (event: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    setFormNewTable({ ...formNewTable, [name]: value });
  };

  const handleCreateNewTable = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const newTable = { ...formNewTable };
    const selectedTable = tableNames.find((item) => item.name === newTable.name);
    newTable.name = newTable.name+'◈'+selectedTable!.approvecount.toString(); selectedTable!.approvecount += 1;
    console.log(newTable.name);
    newTable.type="newtest"
    setFileName(newTable.name)
    setTimeout(() => setFileName(newTable.name), 4000);
    // Создаем новую таблицу из данных формы
    sendTableNamesToServer([...tableNames, newTable]);
    setTableNames([...tableNames, newTable]); // Добавляем новую таблицу в массив
    // Сброс значения формы
    setFormNewTable({name: '', type: 'new', matcode: '', batch: '', expiry: '', rangetable: '', cvrule: '',tablecreator:'', stamp: '', stampauthor: '', stamptext: '', stamptime: '', approvecount:0});
  };








  useEffect(() => {
    if (loggedIn) {
      setHideTableForChangeTrans(true);
      setHideTableForChange(true);
      console.log('fetch')
      const fetchData = async () => {
        try {
          console.log(fileName)
          const response = await fetch(`https://ldx.cloudronics.com/api/data/${fileName}`, { method: 'GET', mode: 'cors', headers: {'Content-Type': 'application/json'}, });
          const data = await response.json();
          // console.log('data',data);
          console.log('Get data successfully');
          // console.log(fileName)
          if (selectedTable.type=="toapprove" || selectedTable.type=="approved") {
            console.log('Get data:',data)
            setTableData(data.table);
            setTableDataSelected(data.selectedtable);
            indexesLeft = data.indexesleft; indexesRight = data.indexesright;
            console.log('indexesLeft,indexesRight:',indexesLeft,indexesRight)
            setSelectedRowIdLeft(null); setSelectedRowIdRight(null);
            setSelectedRowLeftArray([]); setSelectedRowRightArray([]);
          }
          else {
            console.log('Get data simple:',data)
            setTableData(data);
            setTableDataSelected([])
            indexesLeft = []; indexesRight = [];
            console.log('indexesLeft,indexesRight:',indexesLeft,indexesRight)
            setSelectedRowIdLeft(null); setSelectedRowIdRight(null);
            setSelectedRowLeftArray([]); setSelectedRowRightArray([]);
          }

          setTimeout(() => setHideTableForChangeTrans(false), 1000);
          setTimeout(() => setHideTableForChange(false), 2000);

          // setDataLoaded(true);
          // if (dataLoadedFirst===false){
          //   while (tableNames.length<1) {console.log('wait!');continue}
          //   setFileName(tableNames[0].name)
          //   setTimeout(() => setDataLoadedFirst(true), 1000);
          //   setTimeout(() => setShowBlockLogin(false), 2000);}
          } catch (error) {
          console.error('Error fetching data:', error);
          setTableData([]); setTableDataSelected([]);
        }
      };
      // setDataLoaded(false);
      console.log('Get data successfully');
      fetchData();
      // const intervalId = setInterval(fetchData, 10000);
      //
      // return () => clearInterval(intervalId);
    }
  }, [loggedIn, fileName]);

  // const sendDataToServer = async (data: Person[]) => {
  //   try {
  //     const response = await fetch(`https://ldx.cloudronics.com/api/data/${fileName}`, {
  //       method: 'POST', headers: { 'Content-Type': 'application/json' },
  //       body: JSON.stringify(selectedTable.type=="toapprove" || selectedTable.type === "approved" ? {"table": tableData, "selectedtable": tableDataSelected, "indexesleft": indexesLeft, "indexesRight": indexesRight } : data)
  //     });
  //
  //     if (!response.ok) {
  //       throw new Error('Failed to send data to server');
  //     }
  //
  //     console.log('Sent data successfully');
  //   } catch (error) {
  //     console.error('Error sending data to server:', error);
  //   }
  // };



  // useEffect(() => { sendDataToServer(tableData); }, [tableData]);


  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [usertype, setUsertype] = useState('simple');

  const handleUsernameChange = (event: React.ChangeEvent<HTMLInputElement>) => {setUsername(event.target.value); };
  const handlePasswordChange = (event: React.ChangeEvent<HTMLInputElement>) => {setPassword(event.target.value); };
  // const handleFileNameChangeD = (event: React.ChangeEvent<HTMLSelectElement>) => { setFileName(event.target.value); };
  const handleFileNameChange = (newFileName: string) => {setFileName(newFileName);};
  // const handleNewFileNameChange = (newFileName: string) => { setNewTableFileName(newFileName);};
  const handleNewFileNameSelectChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const { value } = event.target;
    // setFormNewTable({ ...formNewTable, name: value });
    const selectedTableName = event.target.value;
    const selectedTable = tableNames.find((item) => item.name === selectedTableName);
    if (selectedTable) {
      setFormNewTable({
        ...formNewTable,
        name: value,
        rangetable: selectedTable.rangetable,
        cvrule: selectedTable.cvrule // Устанавливаем значение из выбранной таблицы
      });
    }
  };

  const [tablesFilters, setTablesFilters] = useState<{ simple: boolean; toapprove: boolean; approved: boolean; name: boolean; date: boolean }>({simple: true, toapprove: true, approved: true, name: true, date: false  });

  // const handleCheckboxTablesFilterChange = (newfilter) => {
  //   if (newfilter==="simple") {settablesFilters()}
  //   setFileName(newFileName);};
  const handleCheckboxTablesFilterChange = (type: keyof typeof tablesFilters) => {
    setTablesFilters((prevState) => ({
      ...prevState,
      [type]: !prevState[type]
    }));
  };



  const handleSubmitLogin = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setStartLoggedIn(true);
    try {
      const response = await fetch(`https://ldx.cloudronics.com/api/login?name=${username}&password=${password}`);
      if (response.ok) {
        const data = await response.json();
        setUsertype(data.type);
        console.log('Login successful');
        setLoggedIn(true);
      } else {
        console.error('Login failed');
        setLoggedInError(true);
      }
    } catch (error) {
      console.error('Error:', error);
    }
  };

  const addRow = () => {
    // const newId = tableData.length + 1;
    const newId = Math.max(...tableData.map(item => item.id)) + 1;
    const currentDate = new Date().toISOString().slice(0, 10); // Получаем текущую дату в формате YYYY-MM-DD
    const newRow: Person = { id: newId, name: username.charAt(0).toUpperCase() + username.slice(1), date: currentDate, a: 0, b: 0, c: 0, d: 0, e: 0, f: 0, c1: 0, c2: 0, lotno: '', remarks: '',};
    setTableData([...tableData, newRow]);
  };

  const deleteRow = (index: number) => {
    const updatedTableData = [...tableData];
    updatedTableData.splice(index, 1); // Удаляем строку по индексу
    setTableData(updatedTableData);
  };

  // Функция для обновления значения в таблице
  const handleValueChange = (id: number, field: keyof Person, value: string | number, index: number) => {
    const updatedData = tableData.map((row, i) => {
      if (i === index) {
        return {...row, [field]: value};
      }
      return row;
    });
    setTableData(updatedData);
    // Если меняется значение в столбце 'a', 'b', 'c' или 'd', обновляем соответствующее значение в столбце 'e'
    // if (field === 'a' || field === 'b' || field === 'c' || field === 'd') {
    //   const updatedDataWithE = updatedData.map(row => ({
    //     ...row,
    //     e: row.a * row.b * row.c * row.d
    //   }));
    //   setTableData(updatedDataWithE);
    // } else {
    //   setTableData(updatedData);
    // }
  };

  const handleValueChangeSelected = (id: number, field: keyof Person, value: string | number, index: number) => {
    const updatedData = tableDataSelected.map((row, i) => {
      if (i === index) {
        return {...row, [field]: value};
      }
      return row;
    });
    setTableDataSelected(updatedData);
  };

  // Функция для определения максимальной ширины в столбце
  const getMaxWidth = (column: (string | number)[]) => {
    let maxWidth = 0;
    column.forEach(value => {
      const width = String(value).length * 8; // 8 - это примерная ширина символа
      if (width > maxWidth) {
        maxWidth = width;
      }
    });
    if (maxWidth<30) {maxWidth = 30;}
    return maxWidth+24 + 'px';
  };

  // Функция для определения ширины столбца, равной сумме ширин переданных ячеек
  const getTotalWidth = (columns: (keyof Person)[]) => {
    let totalWidth = 0;
    columns.forEach(column => {
      const columnWidth = getMaxWidth(tableData.map(data => data[column]));
      totalWidth += parseInt(columnWidth);
    });
    return totalWidth-10+ 'px';
  };

  const calculateAverage = (column: (number | undefined)[]) => {
    const parsedValues = column.map(value => {
      if (typeof value === 'string') {
        const parsedValue = parseFloat(value);
        if (!isNaN(parsedValue)) { return parsedValue; }
      }
      return value;
    });
    const filteredValues = parsedValues.filter(value => typeof value === 'number');
    if (filteredValues.length === 0) return 0;
    const sum = filteredValues.reduce((acc: number, curr) => acc + curr!, 0);
    return sum / filteredValues.length;
  };

  // Создаем массив со средними значениями для каждого столбца
  const averageValues: Person = {
    id: NaN,
    name: '',
    date: '',
    a: calculateAverage(tableDataSelected.map(data => data.a)),
    b: calculateAverage(tableDataSelected.map(data => data.b)),
    c: calculateAverage(tableDataSelected.map(data => data.c)),
    d: calculateAverage(tableDataSelected.map(data => data.d)),
    e: calculateAverage(tableDataSelected.map(data => data.e)),
    f: calculateAverage(tableDataSelected.map(data => data.f)),
    c1: calculateAverage(tableDataSelected.map(data => data.c1)),
    c2: calculateAverage(tableDataSelected.map(data => data.c2)),
    lotno: '',
    remarks: '',
  };

  // Функция для вычисления стандартного отклонения
  const calculateStdev = (column: number[]) => {
    const parsedValues = column.map(value => {
        if (value === null) {
            return NaN; // Заменяем null на NaN
        } else if (typeof value === 'string') {
            const parsedValue = parseFloat(value);
            if (!isNaN(parsedValue)) {
                return parsedValue;
            }
        }
        return value;
    });

    if (parsedValues.length === 0 || parsedValues.every(value => isNaN(value))) {
        return 0;
    }

    const stdevValue = std(parsedValues);
    if (typeof stdevValue === 'number') {
        return stdevValue;
    } else {
        return 0;
    }
};


  // Вычисляем стандартное отклонение для каждого столбца
  const stdevValues: { [key: string]: number } = {
    a: calculateStdev(tableDataSelected.map(data => data.a)),
    b: calculateStdev(tableDataSelected.map(data => data.b)),
    c: calculateStdev(tableDataSelected.map(data => data.c)),
    d: calculateStdev(tableDataSelected.map(data => data.d)),
    e: calculateStdev(tableDataSelected.map(data => data.e)),
    f: calculateStdev(tableDataSelected.map(data => data.f)),
    c1: calculateStdev(tableDataSelected.map(data => data.c1)),
    c2: calculateStdev(tableDataSelected.map(data => data.c2)),
  };

  const thirdRowValues: { [key: string]: number } = {
    a: stdevValues.a / averageValues.a  * 100,
    b: stdevValues.b / averageValues.b * 100,
    c: stdevValues.c / averageValues.c * 100,
    d: stdevValues.d / averageValues.d * 100,
    e: stdevValues.e / averageValues.e * 100,
    f: stdevValues.f / averageValues.f * 100,
    c1: stdevValues.c1 / averageValues.c1 * 100,
    c2: stdevValues.c2 / averageValues.c2 * 100,
  };


  const [selectedRowLeft, setSelectedRowLeft] = useState<{a: string; b: string; c: string; d: string; e: string; f: string;id: number; } | null>(null);
  const [selectedRowRight, setSelectedRowRight] = useState<{c1: string; c2: string, lotno: string, remarks: string,id: number } | null>(null);

  const [selectedRowIdLeft, setSelectedRowIdLeft] = useState<number | null>(null);
  const [selectedRowIdRight, setSelectedRowIdRight] = useState<number | null>(null);

  const [selectedRowLeftArray, setSelectedRowLeftArray] = useState<{a: string; b: string; c: string; d: string; e: string; f: string; id: number }[]>([]);
  const [selectedRowRightArray, setSelectedRowRightArray] = useState<{c1: string; c2: string, lotno: string, remarks: string, id: number }[]>([]);

  const handleRowSelectLeft = (a: string, b: string, c: string, d: string, e: string, f: string, id: number) => {
    setSelectedRowLeft({a,b,c,d,e,f, id });
    setSelectedRowIdLeft(id);
  };
  const handleRowSelectRight = (c1: string, c2: string, lotno: string, remarks: string, id: number) => {
    setSelectedRowRight({c1, c2, lotno, remarks, id });
    setSelectedRowIdRight(id);
  };
  const addRowWithselectedTable = () => {
    if (selectedRowLeft && selectedRowRight) {
      const newId = Math.max(...tableData.map(item => item.id)) + 1;
      const currentDate = new Date().toISOString().slice(0, 10);
      const newRow: Person = {
        id: tableDataSelected.length+1,
        name: username.charAt(0).toUpperCase() + username.slice(1),
        date: currentDate,
        // Добавляем данные из выбранных строк
        a: parseFloat(selectedRowLeft.a),
        b: parseFloat(selectedRowLeft.b),
        c: parseFloat(selectedRowLeft.c),
        d: parseFloat(selectedRowLeft.d),
        e: parseFloat(selectedRowLeft.e),
        f: parseFloat(selectedRowLeft.f),
        c1: parseFloat(selectedRowRight.c1),
        c2: parseFloat(selectedRowRight.c2),
        lotno: selectedRowRight.lotno,
        remarks: selectedRowRight.remarks,
      };
      setTableDataSelected([...tableDataSelected, newRow]);
      setSelectedRowLeftArray([...selectedRowLeftArray, selectedRowLeft]);
      setSelectedRowRightArray([...selectedRowRightArray, selectedRowRight]);

      indexesLeft.push(selectedRowLeft.id);
      indexesRight.push(selectedRowRight.id);

      setSelectedRowLeft(null);
      setSelectedRowRight(null);
      setSelectedRowIdLeft(null);
      setSelectedRowIdRight(null);
    }
  };

  const deleteRowSelected = (index: number) => {
    const updatedTableData = [...tableDataSelected];
    updatedTableData.splice(index, 1); // Удаляем строку по индексу
    setTableDataSelected(updatedTableData);
    if (updatedTableData.length < 1) {deleteAllSelectedRows();}
    // console.log(tableDataSelected,tableDataSelected.length);
  };

  const deleteAllSelectedRows = () => {
    setTableDataSelected([]);
    setSelectedRowLeftArray([]);
    setSelectedRowRightArray([]);
    indexesLeft.splice(0, indexesLeft.length);
    indexesRight.splice(0, indexesRight.length);

  };

  const shuffleRows = () => {
    const newRowsArray: Person[] = [];

    selectedRowLeftArray.forEach((leftRow) => {
      selectedRowRightArray.forEach((rightRow) => {
        const newId = newRowsArray.length + 1;
        const currentDate = new Date().toISOString().slice(0, 10);
        const newRow: Person = {
          id: newId,
          name: username.charAt(0).toUpperCase() + username.slice(1),
          date: currentDate,
          // Добавляем данные из выбранных строк
          a: parseFloat(leftRow.a),
          b: parseFloat(leftRow.b),
          c: parseFloat(leftRow.c),
          d: parseFloat(leftRow.d),
          e: parseFloat(leftRow.e),
          f: parseFloat(leftRow.f),
          c1: parseFloat(rightRow.c1),
          c2: parseFloat(rightRow.c2),
          lotno: rightRow.lotno,
          remarks: rightRow.remarks,
        };
        newRowsArray.push(newRow);
      });
    });

    // Очищаем и добавляем новые строки в таблицу
    setTableDataSelected(newRowsArray);
  };

  const saveSelectedRows = async () => {
    const confirmation = window.confirm("Are you sure? It can't be undone.");
    if (confirmation) {
      console.log("yes")
      try {
        const response = await fetch(`https://ldx.cloudronics.com/api/data/${fileName+'◈'+selectedTable.approvecount.toString()}`, {
          method: 'POST', headers: {'Content-Type': 'application/json'},
          body: JSON.stringify({"table": tableData, "selectedtable": tableDataSelected, "indexesleft": indexesLeft, "indexesright": indexesRight })
        });
        if (!response.ok) { throw new Error('Failed to send data to server'); }
        else {
          console.log('Sent data successfully');
          // console.log(selectedTable.approvecount)
          // Создаем новую таблицу из данных формы
          const newTable = {name: fileName + '◈' + selectedTable.approvecount.toString(), type: 'toapprove', matcode: selectedTable.matcode, batch: selectedTable.batch, expiry: selectedTable.expiry, rangetable: selectedTable.rangetable, cvrule: selectedTable.cvrule, tablecreator: username, stamp: '', stampauthor: '', stamptext: '', stamptime: '', approvecount: 0};
          selectedTable.approvecount += 1;
          // console.log(selectedTable.approvecount)
          sendTableNamesToServer([...tableNames, newTable]);
          setTableNames([...tableNames, newTable]); // Добавляем новую таблицу в массив
          // console.log("Ответ получен")
          setTimeout(() => setFileName(fileName + '◈' + (selectedTable.approvecount - 1).toString()), 1000)

        }} catch (error) {console.error('Error sending data to server:', error);}
    } else { return 0; }
  };


  const [formNewStamp, setFormNewStamp] = useState<TableNames>({
    name: '', type: 'new', matcode: '', batch: '', expiry: '', rangetable: '', cvrule: '', tablecreator: '',
    stamp: '', stampauthor: '', stamptext: '', stamptime: '', approvecount:0
  });
  const handleChangeNewStamp = (event: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    console.log(name,value)
    setFormNewStamp({ ...formNewStamp, [name]: value });
  };
  //
  const handleCreateNewStamp = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const confirmation = window.confirm("Are you sure? It can't be undone.");
    if (formNewStamp.stamptext.length < 2)
      {console.log("Stamp Length < 2"); alert("Stamp Length is less than 2 characters!"); return;}
    if (confirmation) {
      const updatedTableNames = [...tableNames];
      // Находим индекс выбранного элемента в массиве
      const selectedIndex = updatedTableNames.findIndex(item => item.name === selectedTable.name);
      // Если найден индекс выбранного элемента
      if (selectedIndex !== -1) {
        // Копируем выбранный элемент
        const updatedSelectedTable = {...updatedTableNames[selectedIndex]};
        // Обновляем выбранный элемент новыми данными из формы
        updatedSelectedTable.name = updatedSelectedTable.name;
        updatedSelectedTable.type = 'approved';
        updatedSelectedTable.matcode = updatedSelectedTable.matcode;
        updatedSelectedTable.batch = updatedSelectedTable.batch;
        updatedSelectedTable.expiry = updatedSelectedTable.expiry;
        updatedSelectedTable.cvrule = updatedSelectedTable.cvrule;
        updatedSelectedTable.tablecreator = updatedSelectedTable.tablecreator;
        updatedSelectedTable.stamp = 'approved';
        updatedSelectedTable.stampauthor = username;
        updatedSelectedTable.stamptext = formNewStamp.stamptext;
        updatedSelectedTable.stamptime = currentDate;
        updatedSelectedTable.approvecount = updatedSelectedTable.approvecount;
        // Обновляем массив tableNames с обновленным выбранным элементом
        updatedTableNames[selectedIndex] = updatedSelectedTable;
        console.log(updatedTableNames)
        sendTableNamesToServer(updatedTableNames);
        setTableNames(updatedTableNames);
      }
    }
    // const newTable = { ...formNewStamp }; // Создаем новую таблицу из данных формы
    // const updatedTableNames = tableNames.map((table) => {
    //   if (table.name === selectedTable.name) {
    //     // Обновляем выбранные данные в этой таблице
    //     return {
    //       ...table,
    //       ...formNewStamp
    //     };
    //   }
    //   return table;
    // });
    // console.log(updatedTableNames)
    // setTableNames(updatedTableNames);
    // sendTableNamesToServer([...tableNames, newTable]);
    // setTableNames([...tableNames, newTable]); // Добавляем новую таблицу в массив
    // // Сброс значения формы
    // setFormNewTable({name: '', type: '', matcode: '', batch: '', expiry: '', rangetable: '', cvrule: '', stamp: '', stampauthor: '', stamptext: '', stamptime: ''});
  };

  const [currentDate, setCurrentDate] = useState('');

  useEffect(() => {
    // Получение текущей даты
    const today = new Date();
    const year = today.getFullYear();
    const month = String(today.getMonth() + 1).padStart(2, '0');
    const day = String(today.getDate()).padStart(2, '0');
    const formattedDate = `${year}-${month}-${day}`;

    // Сохранение текущей даты в состоянии
    setCurrentDate(formattedDate);
  }, []);



  // Функция для сортировки по имени
  const sortByName = (a: TableNames, b: TableNames) => {
    if (tablesFilters.name) {
      return a.name.split("◈")[0].localeCompare(b.name.split("◈")[0]);
    } else {
      return b.name.split("◈")[0].localeCompare(a.name.split("◈")[0]);
    }
  };

  // Функция для сортировки по дате
  const sortByDate = (a: TableNames, b: TableNames) => {
    const dateA = new Date(a.expiry).getTime();
    const dateB = new Date(b.expiry).getTime();
    if (tablesFilters.date) {
      return dateA - dateB;
    } else {
      return dateB - dateA;
    }
  };

  return (
    <div className={"full_tables_div"}>
      {showBlockLogin && (
        <form onSubmit={handleSubmitLogin} className={"login_form"}>
        <div className={tableNames.length>0 ? 'login_div transparent' : 'login_div'}>
          <h2>Kälvaehto</h2>
          <input className={"login_username"} type="text" placeholder="Username" value={username} onChange={handleUsernameChange} />
          <input className={"login_password"} type="password" placeholder="Password" value={password} onChange={handlePasswordChange} />
          <div className={"login_button_div"}>
            <button type="submit" className={"login_button"}>Sign in</button>
            {startLoggedIn && !loggedIn && !loggedInError && <div className="loader"></div>}
            {loggedInError && !loggedIn  && <div className="loader loader_error"></div>}
            {loggedIn && <div className="loader loader_success"></div>}
            {/*{loggedIn && <div className="loader_success"></div>}*/}
            {/*<div className="loader_success"></div>*/}
          </div>
        </div>
        </form>
      )}

      {dataLoaded && ( <div className={"loader_sendget_file"}></div> )}
      {dataLoaded  && (
          <div className={"user_type_icon"}>
          {usertype==="simple" && (<i className="bi bi-person-fill" title="User"></i>)}
          {usertype==="approver" && (<i className="bi bi-person-fill-check" title="Approver"></i>)}
          {usertype==="admin" && (<i className="bi bi-person-fill-gear" title="Admin"></i>)}
          </div>
      )}

      {/*<div>*/}
      {/*<div className={username==="admin" ? 'load_table_admin' : 'load_table'}d>*/}
      {/*  /!*<div style={{display: "flex", flexDirection: "column"}}>*!/*/}
      {/*  /!*  <h2 style={{margin: "0 auto"}}>Load Table</h2>*!/*/}
      {/*  /!*  /!* Table Names *!/*!/*/}
      {/*  /!*  <table style={{margin: "10px 0"}}>*!/*/}
      {/*  /!*    <thead style={{fontSize: "12px"}}>*!/*/}
      {/*  /!*      <tr style={{backgroundColor: selectedTable.type==="toapprove" ? "#ffec8f": (selectedTable.type==="approved" ? "#caffbb" : "lightgray")}}>*!/*/}
      {/*  /!*        <th style={{width: "140px"}}>Name</th>*!/*/}
      {/*  /!*        <th style={{width: "70px"}}>Mat code</th>*!/*/}
      {/*  /!*        <th style={{width: "50px"}}>Batch</th>*!/*/}
      {/*  /!*        <th style={{width: "70px"}}>Expiry</th>*!/*/}
      {/*  /!*        <th style={{width: "70px"}}>CV rule</th>*!/*/}
      {/*  /!*      </tr>*!/*/}
      {/*  /!*    </thead>*!/*/}
      {/*  /!*    <tbody>*!/*/}
      {/*  /!*      <tr>*!/*/}
      {/*  /!*        <td>*!/*/}
      {/*  /!*          <select*!/*/}
      {/*  /!*              value={fileName} onChange={handleFileNameChange}*!/*/}
      {/*  /!*              style={{width: "140px", border: "none", backgroundColor: "transparent"}}>*!/*/}
      {/*  /!*            {tableNames.map((item, index) => (*!/*/}
      {/*  /!*              <option key={index} value={item.name} style={{backgroundColor: item.type==="toapprove" ? "#ffec8f": (item.type==="approved" ? "#caffbb" : "#fff")}}>{item.name}</option>*!/*/}
      {/*  /!*            ))}*!/*/}
      {/*  /!*          </select>*!/*/}
      {/*  /!*        </td>*!/*/}
      {/*  /!*        <td>*!/*/}
      {/*  /!*          <input*!/*/}
      {/*  /!*            readOnly={true}*!/*/}
      {/*  /!*            value={selectedTable.matcode}*!/*/}
      {/*  /!*            style={{width: "70px"}}*!/*/}
      {/*  /!*          />*!/*/}
      {/*  /!*        </td>*!/*/}
      {/*  /!*        <td>*!/*/}
      {/*  /!*          <input*!/*/}
      {/*  /!*            readOnly={true}*!/*/}
      {/*  /!*            value={selectedTable.batch}*!/*/}
      {/*  /!*            style={{width: "50px"}}*!/*/}
      {/*  /!*          />*!/*/}
      {/*  /!*        </td>*!/*/}
      {/*  /!*        <td>*!/*/}
      {/*  /!*          <input*!/*/}
      {/*  /!*            readOnly={true}*!/*/}
      {/*  /!*            value={selectedTable.expiry}*!/*/}
      {/*  /!*            style={{width: "70px"}}*!/*/}
      {/*  /!*          />*!/*/}
      {/*  /!*        </td>*!/*/}
      {/*  /!*        <td>*!/*/}
      {/*  /!*          <input*!/*/}
      {/*  /!*            readOnly={true}*!/*/}
      {/*  /!*            value={selectedTable.cvrule}*!/*/}
      {/*  /!*            style={{width: "70px"}}*!/*/}
      {/*  /!*          />*!/*/}
      {/*  /!*        </td>*!/*/}
      {/*  /!*      </tr>*!/*/}
      {/*  /!*    </tbody>*!/*/}
      {/*  /!*  </table>*!/*/}
      {/*  /!*</div>*!/*/}

        {tableData.length < 1 && username !== "admin" && (
        <div style={{display: "flex", flexDirection: "column"}}>
          <h2 style={{margin: "30px auto 0"}}>New test</h2>
          <form onSubmit={handleCreateNewTable} style={{display: "flex", flexDirection: "column"}}>


            {/*<input className={"login_username"} type="text" placeholder="Test Name"  value={formNewTable.name}  name="name" onChange={handleChangeNewTable} />*/}
            {/*<select*/}
            {/*    className={"login_username"}*/}
            {/*    value={formNewTable.name}*/}
            {/*    onChange={(e) => handleNewFileNameChange(e.target.value)}*/}
            {/*    style={{border: "none"}} >*/}
            {/*    {tableNames.map((item, index) => (*/}
            {/*        <option key={index} value={item.name} style={{backgroundColor: item.type==="toapprove" ? "#ffec8f": (item.type==="approved" ? "#caffbb" : "#fff")}}>{item.name}</option>*/}
            {/*    ))}*/}
            {/*</select>*/}
            <select
              className={"login_username"}
              value={formNewTable.name}
              onChange={handleNewFileNameSelectChange}
              style={{ border: "none", width: "217px" }} >
              {tableNames.map((item, index) => (
                // Проверяем, что тип не "toapprove" и не "approved"
                (item.type !== "toapprove" && item.type !== "approved" && item.type !== "newtest" ) && (
                  <option
                    key={index}
                    value={item.name}
                    style={{
                      backgroundColor: "#fff", // Фоновый цвет для других элементов
                    }}
                  >
                    {item.name}
                  </option>
                )
              ))}
            </select>

            <input className={"login_username"} type="text" placeholder="Mat code"  value={formNewTable.matcode}  name="matcode" onChange={handleChangeNewTable} />
            <input className={"login_username"} type="text" placeholder="Batch"  value={formNewTable.batch}  name="batch" onChange={handleChangeNewTable} />
            <input className={"login_username"} type="date" placeholder="Expiry"  value={formNewTable.expiry}  name="expiry" onChange={handleChangeNewTable} />

            <input className={"login_username"} type="text" placeholder="Range table"  value={formNewTable.rangetable}  name="rangetable" onChange={handleChangeNewTable} />
            <input className={"login_username"} type="text" placeholder="CV rule"  value={formNewTable.cvrule}  name="cvrule" onChange={handleChangeNewTable} />

            <button type="submit" className={"login_button"}>Create</button>
            {/*<table style={{margin: "10px 0 0"}}>*/}
            {/*  <thead style={{fontSize: "12px"}}>*/}
            {/*    <tr style={{backgroundColor: "lightgray"}}>*/}
            {/*      <th style={{width: "140px"}}>Test Name</th>*/}
            {/*      <th style={{width: "70px"}}>Mat code</th>*/}
            {/*      <th style={{width: "50px"}}>Batch</th>*/}
            {/*      /!*вернуть*!/*/}
            {/*      /!*<th style={{width: "70px"}}>Expiry</th>*!/*/}
            {/*      <th style={{width: "120px"}}>Range Table</th>*/}
            {/*      <th style={{width: "90px"}}>CV rule</th>*/}
            {/*    </tr>*/}
            {/*  </thead>*/}
            {/*  <tbody>*/}
            {/*    <tr>*/}
            {/*      <td>*/}
            {/*        <input*/}
            {/*          type="text"*/}
            {/*          name="name"*/}
            {/*          style={{width: "140px"}}*/}
            {/*          value={formNewTable.name}*/}
            {/*          onChange={handleChangeNewTable}*/}
            {/*        />*/}
            {/*      </td>*/}
            {/*      <td>*/}
            {/*        <input*/}
            {/*          type="text"*/}
            {/*          name="matcode"*/}
            {/*          style={{width: "70px"}}*/}
            {/*          value={formNewTable.matcode}*/}
            {/*          onChange={handleChangeNewTable}*/}
            {/*        />*/}
            {/*      </td>*/}
            {/*      <td>*/}
            {/*        <input*/}
            {/*          type="text"*/}
            {/*          name="batch"*/}
            {/*          style={{width: "50px"}}*/}
            {/*          value={formNewTable.batch}*/}
            {/*          onChange={handleChangeNewTable}*/}
            {/*        />*/}
            {/*      </td>*/}
            {/*      <td>*/}
            {/*        <input*/}
            {/*          type="text"*/}
            {/*          name="expiry"*/}
            {/*          style={{width: "70px"}}*/}
            {/*          value={formNewTable.expiry}*/}
            {/*          onChange={handleChangeNewTable}*/}
            {/*        />*/}
            {/*      </td>*/}
            {/*      <td>*/}
            {/*        <input*/}
            {/*          type="text"*/}
            {/*          name="cvrule"*/}
            {/*          style={{width: "70px"}}*/}
            {/*          value={formNewTable.cvrule}*/}
            {/*          onChange={handleChangeNewTable}*/}
            {/*        />*/}
            {/*      </td>*/}
            {/*    </tr>*/}
            {/*  </tbody>*/}
            {/*</table>*/}
            {/*/!*<button type="submit">Добавить</button>*!/*/}
            {/*<button type="submit" className={"button_createtable"} style={{marginLeft: '0px', marginTop: '3px'}}><i className="bi bi-plus-square"></i></button>*/}
          </form>
        </div>
        )}
      {/*</div>*/}
      {/*</div>*/}


      {/*Выбор таблицы*/}
      {loggedIn && username==="admin" && (
      <div style={{display: "flex", flexDirection: "row", marginTop: "50px", marginBottom: "10px"}}>
        <h2 style={{margin: "auto 30px auto 0"}}>Select a table</h2>
        {/*<div className="checkbox-wrapper">*/}
        {/*  <input*/}
        {/*    type="checkbox"*/}
        {/*    id={"cbx-1"}*/}
        {/*    checked={tablesFilters && tablesFilters.simple}*/}
        {/*    onChange={() => handleCheckboxTablesFilterChange("simple")}*/}
        {/*    // checked={topping}*/}
        {/*    // onChange={() => handleCheckboxTablesFilterChange("simple")}*/}
        {/*  />*/}
        {/*  <label className="cbx" htmlFor={"cbx-1"}></label>*/}
        {/*  <label className="lbl" htmlFor={"cbx-1"}> <span><i className="bi bi-file-earmark"></i></span></label>*/}
        {/*</div>*/}
        <div className="checkbox-wrapper">
          <input
            type="checkbox"
            id={"cbx-2"}
            checked={tablesFilters && tablesFilters.toapprove}
            onChange={() => handleCheckboxTablesFilterChange("toapprove")}
            // checked={topping}
            // onChange={() => handleCheckboxTablesFilterChange("simple")}
          />
          <label className="cbx" htmlFor={"cbx-2"}></label>
          <label className="lbl" htmlFor={"cbx-2"}> <span><i className="bi bi-file-earmark-check-fill" style={{color: "#f6c100"}}></i></span></label>
        </div>
        <div className="checkbox-wrapper">
          <input
            type="checkbox"
            id={"cbx-3"}
            checked={tablesFilters && tablesFilters.approved}
            onChange={() => handleCheckboxTablesFilterChange("approved")}
            // checked={topping}
            // onChange={() => handleCheckboxTablesFilterChange("simple")}
          />
          <label className="cbx" htmlFor={"cbx-3"}></label>
          <label className="lbl" htmlFor={"cbx-3"}> <span><i className="bi bi-file-earmark-check-fill" style={{color: "#3c6cb1"}}></i></span></label>
        </div>

        <div onClick={() => handleCheckboxTablesFilterChange("date")}
             style={{marginRight: "15px", cursor: "pointer", height: "28px", marginTop: "auto", marginBottom: "auto"}}>
          {tablesFilters && tablesFilters.date && (
            <i className="bi-sort-numeric-down" style={{fontSize: "28px"}}></i> )}
          {tablesFilters && !tablesFilters.date && (
            <i className="bi-sort-numeric-up" style={{fontSize: "28px"}}></i> )}
        </div>

        <div onClick={() => handleCheckboxTablesFilterChange("name")}
             style={{marginRight: "15px", cursor: "pointer", height: "28px", marginTop: "auto", marginBottom: "auto"}}>
          {tablesFilters && tablesFilters.name && (
            <i className="bi bi-sort-alpha-down" style={{fontSize: "28px"}}></i> )}
          {tablesFilters && !tablesFilters.name && (
            <i className="bi bi-sort-alpha-up" style={{fontSize: "28px"}}></i> )}
        </div>
        <table style={{margin: "0px 0 0 20px"}}>
          <thead style={{fontSize: "12px"}}>
            <tr style={{ color: "white"}}>
              <th style={{backgroundColor: "#5b82b9",  border: "0px solid black", borderTopLeftRadius: "7px", padding: "0 10px"}}>Mat code</th>
              <th style={{backgroundColor: "#5b82b9", padding: "0 10px"}}>Batch</th>
              <th style={{backgroundColor: "#5b82b9",  border: "0px solid black", borderTopRightRadius: "7px", padding: "0 10px"}}>CV rule</th>
              {/*<th style={{width: "70px"}}>CV rule</th>*/}
            </tr>
          </thead>
          <tbody>
            <tr>
              <td style={{textAlign: "center", padding: "0 10px"}}>{selectedTable.matcode}</td>
              <td style={{textAlign: "center", padding: "0 10px", borderLeft: "solid 1px #cccccc", borderRight: "solid 1px #cccccc"}}>{selectedTable.batch}</td>
              <td style={{textAlign: "center", padding: "0 10px"}}>{selectedTable.cvrule}</td>
            </tr>
          </tbody>
        </table>
      </div>
      )}
      {loggedIn && username==="admin" && (
      <div className={"full_table_spisok"}>
        {tableNames.sort(sortByName).sort(sortByDate).map((item, index) => {
            if (item.type === "new") {return null;}
            // if (!tablesFilters.simple && item.type === "new") {return null;}
            if (!tablesFilters.toapprove && item.type === "toapprove") {return null;}
            if (!tablesFilters.approved && item.type === "approved") {return null;}
            if (item.type === "newtest" ) {return null;}
            return (
            <div className={"table_spisok_one_div"}
                 onClick={() => handleFileNameChange(item.name)}
                 style={{ border: item.name===fileName ? "3px solid #5b82b9" : "3px solid #dedede",
                          // margin: item.name===fileName ? "0 10px 10px 0;" : "3px 13px 13px 3px;"
            }}>
              <div>{item.name.split("◈")[0]}</div>
              <div className={"table_spisok_one_input_div"}>
                {item.tablecreator && (
                  <p>{item.tablecreator}</p>
                )}
                <input type="date" className={"table_spisok_one_input_date"} value={item.expiry} ></input>
              </div>

              {item.type==="toapprove" && (
                <div className={"table_spisok_one_div_type"} style={{backgroundColor: "#ffe17d",
                     borderLeft: item.name===fileName ? "3px solid #5b82b9" : "3px solid #cecece"}}>
              <i className="bi bi-check-circle"></i></div>
              )}
              {item.type==="approved" && (
                <div className={"table_spisok_one_div_type-approved"} title={item.stamptime}
                     style={{backgroundColor: "#a3dbfb", borderLeft: item.name===fileName ? "3px solid #5b82b9" : "3px solid #cecece"}}>
                  <i className={"bi bi-check-circle-fill"} style={{color: "#3c6cb1"}}></i>
                  <p>{item.stampauthor}</p>
                </div>
              )}
            </div>
        )})}
      </div>
      )}


      {/*Характеристики нового теста*/}
      {tableData.length > 0 && (
      <table style={{margin: "50px 0 0 20px"}}>
        <thead style={{fontSize: "12px"}}>
          <tr style={{ color: "white"}}>
            <th style={{backgroundColor: "#5b82b9",  border: "0px solid black", borderTopLeftRadius: "7px", padding: "0 10px"}}>Test Name</th>
            <th style={{backgroundColor: "#5b82b9", padding: "0 10px"}}>Mat code</th>
            <th style={{backgroundColor: "#5b82b9", padding: "0 10px"}}>Batch</th>
            <th style={{backgroundColor: "#5b82b9", padding: "0 10px"}}>Expiry</th>
            <th style={{backgroundColor: "#5b82b9", padding: "0 10px"}}>Range Table</th>
            <th style={{backgroundColor: "#5b82b9",  border: "0px solid black", borderTopRightRadius: "7px", padding: "0 10px"}}>CV rule</th>
            {/*<th style={{width: "70px"}}>CV rule</th>*/}
          </tr>
        </thead>
        <tbody>
          <tr>
            <td style={{textAlign: "center", padding: "0 10px"}}>{selectedTable.name.split("◈")[0]}</td>
            <td style={{textAlign: "center", padding: "0 10px", borderLeft: "solid 1px #cccccc", borderRight: "solid 1px #cccccc"}}>{selectedTable.matcode}</td>
            <td style={{textAlign: "center", padding: "0 10px", borderLeft: "solid 1px #cccccc", borderRight: "solid 1px #cccccc"}}>{selectedTable.batch}</td>
            <td style={{textAlign: "center", padding: "0 10px", borderLeft: "solid 1px #cccccc", borderRight: "solid 1px #cccccc"}}>{selectedTable.expiry}</td>
            <td style={{textAlign: "center", padding: "0 10px", borderLeft: "solid 1px #cccccc", borderRight: "solid 1px #cccccc"}}>{selectedTable.rangetable}</td>
            <td style={{textAlign: "center", padding: "0 10px"}}>{selectedTable.cvrule}</td>
            
          </tr>
        </tbody>
      </table>
      )}


      {/*Основная таблица*/}
      {tableData.length > 0 && (
      <div style={{display: "flex", position: "relative"}}>
        {hideTableForChange && (<div className={hideTableForChangeTrans===true ? "white_block_for_reload" : "white_block_for_reload transparent"}>
          <div className="loader" style={{margin: "0 auto"}}></div>
        </div>)}

        <table style={{marginRight: "0"}}>
          <thead>
            <tr>
              <th colSpan={3} style={{borderBottom: "2px solid black"}}></th>
              <th colSpan={6} style={{ backgroundColor: '#afaff1', borderTopLeftRadius: "10px", borderTopRightRadius: "10px", border: "solid 2px"}}>Calibrators</th>
            </tr>
            <tr style={{backgroundColor: '#5b82b9', color: "white"}}>
              <th style={{borderLeft: "2px solid black"}}>Sr No.</th>
              <th colSpan={1} style={{ textAlign: 'left' }}>Name</th>
              <th colSpan={1} style={{ textAlign: 'left' }}>Date</th>
              <th style={{borderLeft: "2px solid black"}}>A</th>
              <th>B</th>
              <th>C</th>
              <th>D</th>
              <th>E</th>
              <th style={{borderRight: "2px solid black"}}>F</th>

              <th style={{width: "25px", backgroundColor: "white"}}></th>
              {/*<th style={{border: 'none', backgroundColor: '#fff'}}></th>*/}
              {/*<th>C<sub>1</sub></th>*/}
              {/*<th>C<sub>2</sub></th>*/}
              {/*<th>E</th>*/}
            </tr>
          </thead>
          <tbody>
            {tableData.map((row, index) => (
              <tr key={row.id}
                  className={indexesLeft.includes(row.id) ? (selectedRowIdLeft === row.id ? 'selected_row' : 'row_in_selected') : (selectedRowIdLeft === row.id ? 'selected_row' : '') }>

                <td style={{  textAlign: 'center', borderLeft: "2px solid black",
                              borderBottom: tableData[tableData.length - 1].id === row.id ? '2px solid black' : '1px solid #ccc',
                              borderBottomLeftRadius: tableData[tableData.length - 1].id === row.id ? '10px' : '',
                              width: getMaxWidth(tableData.map(data => data.id)) }} >{row.id || 0} </td>
                {/*<td style={{borderBottom: tableData[tableData.length - 1].id === row.id ? '2px solid black' : '1px solid #ccc',*/}
                {/*            width: getMaxWidth(tableData.map(data => data.name)) }} >{row.name || 0} </td>*/}

                <td style={{borderBottom: tableData[tableData.length - 1].id === row.id ? '2px solid black' : '1px solid #ccc'}}>
                  <input
                    readOnly={selectedTable.type==="toapprove" || selectedTable.type==="approved"}
                    value={row.name}
                    onChange={e => handleValueChange(row.id, 'name', e.target.value, index)}
                    style={{ width: getMaxWidth(tableData.map(data => data.name)) }}
                  />
                </td>
                <td style={{borderBottom: tableData[tableData.length - 1].id === row.id ? '2px solid black' : '1px solid #ccc'}}>
                  <input
                    type="date"
                    value={row.date}
                    // onChange={e => handleValueChange(row.id, 'date', e.target.value, index)}
                    style={{ width: '100px' }}
                  />
                </td>
                <td style={{borderLeft: "2px solid black", borderBottom: tableData[tableData.length - 1].id === row.id ? '2px solid black' : '1px solid #ccc'}}>
                  <input
                    readOnly={selectedTable.type==="toapprove" || selectedTable.type==="approved"}
                    value={row.a}
                    onChange={e => handleValueChange(row.id, 'a', e.target.value, index)}
                    style={{ width: getMaxWidth(tableData.map(data => data.a)) }}
                  />
                </td>
                <td style={{borderBottom: tableData[tableData.length - 1].id === row.id ? '2px solid black' : '1px solid #ccc'}}>
                  <input
                    readOnly={selectedTable.type==="toapprove" || selectedTable.type==="approved"}
                    value={row.b}
                    onChange={e => handleValueChange(row.id, 'b', e.target.value, index)}
                    style={{ width: getMaxWidth(tableData.map(data => data.b)) }}
                  />
                </td>
                <td style={{borderBottom: tableData[tableData.length - 1].id === row.id ? '2px solid black' : '1px solid #ccc'}}>
                  <input
                    readOnly={selectedTable.type==="toapprove" || selectedTable.type==="approved"}
                    value={row.c}
                    onChange={e => handleValueChange(row.id, 'c', e.target.value, index)}
                    style={{ width: getMaxWidth(tableData.map(data => data.c)) }}
                  />
                </td>
                <td style={{borderBottom: tableData[tableData.length - 1].id === row.id ? '2px solid black' : '1px solid #ccc'}}>
                  <input
                    readOnly={selectedTable.type==="toapprove" || selectedTable.type==="approved"}
                    value={row.d}
                    onChange={e => handleValueChange(row.id, 'd', e.target.value, index)}
                    style={{ width: getMaxWidth(tableData.map(data => data.d)) }}
                  />
                </td>
                <td style={{borderBottom: tableData[tableData.length - 1].id === row.id ? '2px solid black' : '1px solid #ccc'}}>
                  <input
                    readOnly={selectedTable.type==="toapprove" || selectedTable.type==="approved"}
                    value={row.e}
                    onChange={e => handleValueChange(row.id, 'e', e.target.value, index)}
                    style={{ width: getMaxWidth(tableData.map(data => data.e)) }}
                  />
                </td>
                <td style={{borderRight: "2px solid black", borderBottom: tableData[tableData.length - 1].id === row.id ? '2px solid black' : '1px solid #ccc', borderBottomRightRadius: tableData[tableData.length - 1].id === row.id ? '10px' : ''}}>
                  <input
                    readOnly={selectedTable.type==="toapprove" || selectedTable.type==="approved"}
                    value={row.f}
                    onChange={e => handleValueChange(row.id, 'f', e.target.value, index)}
                    style={{ width: getMaxWidth(tableData.map(data => data.f)) }}
                  />
                </td>
                {/*<td style={{ textAlign: 'center', borderLeft: "2px solid black", borderBottom: tableData[tableData.length - 1].id === row.id ? '2px solid black' : '1px solid #ccc',*/}
                {/*    width: getMaxWidth(tableData.map(data => data.a)) }} >{row.a || 0} </td>*/}
                {/*<td style={{ textAlign: 'center', borderBottom: tableData[tableData.length - 1].id === row.id ? '2px solid black' : '1px solid #ccc',*/}
                {/*    width: getMaxWidth(tableData.map(data => data.b)) }} >{row.b || 0} </td>*/}
                {/*<td style={{ textAlign: 'center', borderBottom: tableData[tableData.length - 1].id === row.id ? '2px solid black' : '1px solid #ccc',*/}
                {/*    width: getMaxWidth(tableData.map(data => data.c)) }} >{row.c || 0} </td>*/}
                {/*<td style={{ textAlign: 'center', borderBottom: tableData[tableData.length - 1].id === row.id ? '2px solid black' : '1px solid #ccc',*/}
                {/*    width: getMaxWidth(tableData.map(data => data.d)) }} >{row.d || 0} </td>*/}
                {/*<td style={{ textAlign: 'center', borderBottom: tableData[tableData.length - 1].id === row.id ? '2px solid black' : '1px solid #ccc',*/}
                {/*    width: getMaxWidth(tableData.map(data => data.e)) }} >{row.e || 0} </td>*/}
                {/*<td style={{  textAlign: 'center', borderRight: "2px solid black",*/}
                {/*              borderBottom: tableData[tableData.length - 1].id === row.id ? '2px solid black' : '1px solid #ccc',*/}
                {/*              borderBottomRightRadius: tableData[tableData.length - 1].id === row.id ? '10px' : '',*/}
                {/*              width: getMaxWidth(tableData.map(data => data.f)) }} >{row.f || 0} </td>*/}
                <td style={{border: "none", backgroundColor: "#fff"}}>
                  {selectedTable.type !== "toapprove" && selectedTable.type !== "approved" && (
                  <input
                    type="radio"
                    name="selectedRowLeft"
                    checked={selectedRowIdLeft === row.id}
                    onChange={() => handleRowSelectLeft(row.a.toString(),row.b.toString(),row.c.toString(),row.d.toString(),row.e.toString(),row.f.toString(), row.id)}
                  />)}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
        <div className={"button_rowtoselected_div"} onClick={() => {if (selectedRowIdLeft && selectedRowIdRight) {addRowWithselectedTable();}}} style={{ cursor: selectedRowLeft && selectedRowRight ? "pointer" : "" }}>
          {selectedRowIdLeft && selectedRowIdRight && (
            <button onClick={addRowWithselectedTable} className={"button_rowtoselected"}><i className="bi bi-arrow-bar-down"></i></button>
          )}
        </div>
        <table>
          <thead>
            <tr>
              <th></th>
              <th colSpan={2} style={{ backgroundColor: '#ffc586', borderTopLeftRadius: "10px", borderTopRightRadius: "10px", border: "solid 2px"}}>Controls</th>
              <th colSpan={2} style={{borderBottom: "2px solid black"}}></th>
            </tr>
            <tr style={{backgroundColor: '#5b82b9', color: "white"}}>
              <th style={{backgroundColor: "#fff", width: "25px"}}></th>
              <th style={{borderLeft: "2px solid black"}}>C<sub>1</sub></th>
              <th style={{borderRight: "2px solid black"}}>C<sub>2</sub></th>
              <th style={{ textAlign: 'left', paddingLeft: "5px" }}>Lot</th>
              <th style={{ textAlign: 'left', borderRight: "2px solid black" }}>Remarks</th>
            </tr>
          </thead>
          <tbody>
          {tableData.map((row, index) => (
          // <tr className={`${indexesRight.includes(row.id) ? 'row_in_selected' : ''} ${selectedRowIdRight === row.id ? 'selected_row' : ''} `}>
          <tr className={ indexesRight.includes(row.id) ? (selectedRowIdRight === row.id ? 'selected_row' : 'row_in_selected') : (selectedRowIdRight === row.id ? 'selected_row' : '') }>
            <td style={{border: "none", backgroundColor: "#fff"}} >
              {selectedTable.type !== "toapprove" && selectedTable.type !== "approved" && (
              <input
                type="radio"
                name="selectedRowRight"
                checked={selectedRowIdRight === row.id}
                onChange={() => handleRowSelectRight(row.c1.toString(), row.c2.toString(), row.lotno, row.remarks, row.id)}
              />)}
            </td>
            {/*<td style={{ textAlign: 'center', borderLeft: "2px solid black",*/}
            {/*    borderBottom: tableData[tableData.length - 1].id === row.id ? '2px solid black' : '1px solid #ccc',*/}
            {/*    borderBottomLeftRadius: tableData[tableData.length - 1].id === row.id ? '10px' : '',*/}
            {/*    width: getMaxWidth(tableData.map(data => data.c1)) }} >{row.c1 || 0} </td>*/}
            {/*<td style={{ textAlign: 'center', borderRight: "2px solid black", borderBottom: tableData[tableData.length - 1].id === row.id ? '2px solid black' : '1px solid #ccc',*/}
            {/*    width: getMaxWidth(tableData.map(data => data.c2)) }} >{row.c2 || 0} </td>*/}
            {/*<td style={{ textAlign: 'left', paddingLeft: "5px" ,*/}
            {/*    borderBottom: tableData[tableData.length - 1].id === row.id ? '2px solid black' : '1px solid #ccc',*/}
            {/*    width: getMaxWidth(tableData.map(data => data.lotno)) }} >{row.lotno || 0} </td>*/}
            {/*<td className={"td_remarks"} style={{*/}
            {/*    borderBottom: tableData[tableData.length - 1].id === row.id ? '2px solid black' : '1px solid #ccc',*/}
            {/*    borderBottomRightRadius: tableData[tableData.length - 1].id === row.id ? '10px' : ''}}>*/}
            {/*    <div> {row.remarks || 0} </div>*/}
            {/*</td>*/}
            {/*<td style={{ textAlign: 'left', borderRight: "2px solid black",*/}
            {/*    overflowX: "auto", whiteSpace: "nowrap",*/}
            {/*    borderBottom: tableData[tableData.length - 1].id === row.id ? '2px solid black' : '1px solid #ccc',*/}
            {/*    borderBottomRightRadius: tableData[tableData.length - 1].id === row.id ? '10px' : '',*/}
            {/*    width: "20px" }} >{row.remarks || 0} </td>*/}
                {/*width: getMaxWidth(tableData.map(data => data.remarks)) }} >{row.remarks || 0} </td>*/}
            <td style={{borderLeft: "2px solid", borderBottom: tableData[tableData.length - 1].id === row.id ? '2px solid' : '1px solid #ccc' }}>
              <input
                readOnly={selectedTable.type==="toapprove" || selectedTable.type==="approved"}
                value={row.c1 || 0}
                onChange={e => handleValueChange(row.id, 'c1', e.target.value, index)}
                style={{ width: getMaxWidth(tableData.map(data => data.c1)) }}
              />
            </td>
            <td style={{borderRight: "2px solid", borderBottom: tableData[tableData.length - 1].id === row.id ? '2px solid' : '1px solid #ccc' }}>
              <input
                readOnly={selectedTable.type==="toapprove" || selectedTable.type==="approved"}
                value={row.c2 || 0}
                onChange={e => handleValueChange(row.id, 'c2', e.target.value, index)}
                style={{ width: getMaxWidth(tableData.map(data => data.c2)) }}
              />
            </td>
            <td style={{borderBottom: tableData[tableData.length - 1].id === row.id ? '2px solid black' : '1px solid #ccc'}}>
              <input
                readOnly={selectedTable.type==="toapprove" || selectedTable.type==="approved"}
                value={row.lotno || 0}
                onChange={e => handleValueChange(row.id, 'lotno', e.target.value, index)}
                style={{ width: getMaxWidth(tableData.map(data => data.lotno)) }}
              />
            </td>
            <td style={{borderRight: "2px solid black", borderBottom: tableData[tableData.length - 1].id === row.id ? '2px solid black' : '1px solid #ccc',
                borderBottomRightRadius: tableData[tableData.length - 1].id === row.id ? '10px' : ''}}>
              <input
                readOnly={selectedTable.type==="toapprove" || selectedTable.type==="approved"}
                value={row.remarks || 0}
                onChange={e => handleValueChange(row.id, 'remarks', e.target.value, index)}
                style={{ width: "100px" }}
              />
            </td>
            <td style={{border: 'none'}}>
              {selectedTable.type !== "toapprove" && selectedTable.type !== "approved" &&(
                  <button onClick={() => deleteRow(index)} className="button_newrow"><i className="bi bi-dash-square"></i></button>)}
            </td>
          </tr>
        ))}
          </tbody>
        </table>
      </div>
      )}

      {selectedTable.type !== "toapprove" && selectedTable.type !== "approved" &&(
      <div style={{display: "flex", flexDirection: "row"}}>
        {tableData.length>0 && (<button onClick={addRow} className={"button_newrow"} style={{marginLeft: '0', marginTop: '5px'}}><i className="bi bi-plus-square"></i></button>)} </div>
      )}




      {/* Таблица выбранных строчек*/}
      {tableDataSelected.length > 0 && (
      <div style={{display: "flex", flexDirection: "column", marginTop: "60px", marginLeft: "0px", position: "relative"}}>
        {hideTableForChange && (<div className={hideTableForChangeTrans===true ? "white_block_for_reload" : "white_block_for_reload transparent"}>
          <div className="loader" style={{margin: "0 auto"}}></div> </div>)}

        <div style={{display: "flex", flexDirection: "row"}}>
          <h2>Selected</h2>
          {selectedTable.type !== "toapprove" && selectedTable.type !== "approved" && (
            <div style={{display: "flex", flexDirection: "row"}}>
              <button onClick={deleteAllSelectedRows} className={"button_shuffle"}>
                <i className="bi bi-file-earmark-x"></i>
              </button>
              <button onClick={shuffleRows} className={"button_shuffle"}>
                <i className="bi bi-shuffle"></i>
              </button>
              <button onClick={saveSelectedRows} className={"button_shuffle"}>
                <i className="bi bi-floppy"></i>
              </button>
            </div>
          )}
          {selectedTable.type === "toapprove" && (
              <button className={"button_shuffle"}> <i className="bi bi-floppy2-fill" style={{color: "#5b82b9"}}></i></button> )}
          {selectedTable.type==="approved" && (
                <button type="button" className={"button_submitapprove"}><i className="bi bi-check-circle-fill" style={{color: "#5b82b9"}}></i></button>)}
        </div>

        <div style={{display: "flex", flexDirection: "row"}}>

        <table style={{marginTop: "10px"}} className={"full_selected_table"}>
          <thead>
            <tr>
              <th colSpan={3} style={{borderBottom: "2px solid black"}}></th>
              <th colSpan={6} style={{ backgroundColor: '#afaff1', borderTopLeftRadius: "10px", borderTopRightRadius: "10px", border: "solid 2px"}}>Calibrators</th>
            </tr>
            <tr style={{backgroundColor: '#5b82b9', color: "white"}}>
              <th style={{borderLeft: "2px solid black"}}>Sr No.</th>
              <th colSpan={1} style={{ textAlign: 'left' }}>Name</th>
              <th colSpan={1} style={{ textAlign: 'left' }}>Date</th>
              <th style={{borderLeft: "2px solid black"}}>A</th>
              <th>B</th>
              <th>C</th>
              <th>D</th>
              <th>E</th>
              <th style={{borderRight: "2px solid black"}}>F</th>
              <th style={{width: "32px", backgroundColor: "white"}}></th>
            </tr>
          </thead>
          <tbody>
            {tableDataSelected.map((row, index) => (
              <tr>
                <td style={{  textAlign: 'center', borderLeft: "2px solid black",
                              borderBottom: index === tableDataSelected.length - 1 ? '2px solid black' : ' 1px solid #ccc',
                              borderBottomLeftRadius: index === tableDataSelected.length - 1 ? '10px' : '',
                              width: getMaxWidth(tableData.map(data => data.id)) }} >{row.id || 0} </td>
                <td style={{borderBottom: index === tableDataSelected.length - 1 ? '2px solid black' : '1px solid #ccc',
                            width: getMaxWidth(tableData.map(data => data.name)) }} >{row.name || 0} </td>
                {/*<td style={{ width: '45px', }} >{row.id}*/}
                {/*</td>*/}
                {/*<td>*/}
                {/*  <input*/}
                {/*    readOnly={true}*/}
                {/*    value={row.name}*/}
                {/*    onChange={e => handleValueChangeSelected(row.id, 'name', e.target.value, index)}*/}
                {/*    style={{ width: getMaxWidth(tableData.map(data => data.name)) }}*/}
                {/*  />*/}
                {/*</td>*/}
                <td style={{ borderBottom: index === tableDataSelected.length - 1 ? '2px solid black' : ' 1px solid #ccc'}}>
                  <input
                    readOnly={true}
                    type="date"
                    value={row.date}
                    onChange={e => handleValueChangeSelected(row.id, 'date', e.target.value, index)}
                    style={{ width: '100px', }}
                  />
                </td>
                <td style={{ textAlign: 'center', borderLeft: "2px solid black",
                    borderBottom: index === tableDataSelected.length - 1 ? '2px solid black' : ' 1px solid #ccc',
                    width: getMaxWidth(tableData.map(data => data.a)) }} >{row.a || 0} </td>
                <td style={{ textAlign: 'center',
                    borderBottom: index === tableDataSelected.length - 1 ? '2px solid black' : ' 1px solid #ccc',
                    width: getMaxWidth(tableData.map(data => data.b)) }} >{row.b || 0} </td>
                <td style={{ textAlign: 'center',
                    borderBottom: index === tableDataSelected.length - 1 ? '2px solid black' : ' 1px solid #ccc',
                    width: getMaxWidth(tableData.map(data => data.c)) }} >{row.c || 0} </td>
                <td style={{ textAlign: 'center',
                    borderBottom: index === tableDataSelected.length - 1 ? '2px solid black' : ' 1px solid #ccc',
                    width: getMaxWidth(tableData.map(data => data.d)) }} >{row.d || 0} </td>
                <td style={{ textAlign: 'center',
                    borderBottom: index === tableDataSelected.length - 1 ? '2px solid black' : ' 1px solid #ccc',
                    width: getMaxWidth(tableData.map(data => data.e)) }} >{row.e || 0} </td>
                <td style={{  textAlign: 'center', borderRight: "2px solid black",
                              borderBottomRightRadius: tableDataSelected.length-1 === index ? '10px' : '',
                              borderBottom: index === tableDataSelected.length - 1 ? '2px solid black' : ' 1px solid #ccc',
                              width: getMaxWidth(tableData.map(data => data.f)) }} >{row.f || 0} </td>
              </tr>
            ))}
          </tbody>
        </table>

        <table style={{marginTop: "10px", marginLeft: "22px"}} className={"full_selected_table"}>
          <thead>
            <tr>
              <th colSpan={2} style={{ backgroundColor: '#ffc586', borderTopLeftRadius: "10px", borderTopRightRadius: "10px", border: "solid 2px"}}>Controls</th>
              <th colSpan={2} style={{borderBottom: "2px solid black"}}></th>
            </tr>
            <tr style={{backgroundColor: '#5b82b9', color: "white"}}>
              <th style={{ borderLeft: "2px solid black"}}>C<sub>1</sub></th>
              <th style={{ borderRight: "2px solid black"}}>C<sub>2</sub></th>
              <th style={{ textAlign: 'left', paddingLeft: "5px"  }}>Lot</th>
              <th style={{ textAlign: 'left', borderRight: "2px solid black" }}>Remarks</th>
            </tr>
          </thead>
          <tbody>
          {tableDataSelected.map((row, index) => (
          <tr >
            <td style={{ textAlign: 'center', borderLeft: "2px solid black",
                borderBottomLeftRadius: index === tableDataSelected.length - 1 ? '10px' : '',
                borderBottom: index === tableDataSelected.length - 1 ? '2px solid black' : ' 1px solid #ccc',
                width: getMaxWidth(tableData.map(data => data.c1)) }} >{row.c1 || 0} </td>
            <td style={{ textAlign: 'center', borderRight: "2px solid black",
                borderBottom: index === tableDataSelected.length - 1 ? '2px solid black' : ' 1px solid #ccc',
                width: getMaxWidth(tableData.map(data => data.c2)) }} >{row.c2 || 0} </td>
            <td style={{ paddingLeft: "5px",
                borderBottom: index === tableDataSelected.length - 1 ? '2px solid black' : ' 1px solid #ccc',
                width: getMaxWidth(tableData.map(data => data.lotno)) }} >{row.lotno || 0} </td>
            {/*<td style={{ borderRight: "2px solid black",*/}
            {/*    borderBottomRightRadius: tableDataSelected.length-1 === index ? '10px' : '',*/}
            {/*    borderBottom: index === tableDataSelected.length - 1 ? '2px solid black' : ' 1px solid #ccc',*/}
            {/*    width: getMaxWidth(tableData.map(data => data.remarks)) }} >{row.remarks || 0} </td>*/}
            <td className={"td_remarks"} style={{
                borderBottom: tableDataSelected.length-1 === index ? '2px solid black' : '1px solid #ccc',
                borderBottomRightRadius: tableDataSelected.length-1 === index ?  '10px' : ''}}>
                <div> {row.remarks || 0} </div>
            </td>
              {/*<input*/}
              {/*  readOnly={true}*/}
              {/*  value={row.c1 || 0}*/}
                {/*onChange={e => handleValueChangeSelected(row.id, 'c1', e.target.value, index)}*/}
                {/*style={{ width: getMaxWidth(tableData.map(data => data.c1)) }}*/}
              {/*/>*/}
            {/*<td>*/}
            {/*  <input*/}
            {/*    readOnly={true}*/}
            {/*    value={row.c2 || 0}*/}
            {/*    onChange={e => handleValueChangeSelected(row.id, 'c2', e.target.value, index)}*/}
            {/*    style={{ width: getMaxWidth(tableData.map(data => data.c2)) }}*/}
            {/*  />*/}
            {/*</td>*/}
            {/*<td>*/}
            {/*  <input*/}
            {/*    readOnly={true}*/}
            {/*    value={row.lotno || 0}*/}
            {/*    onChange={e => handleValueChangeSelected(row.id, 'lotno', e.target.value, index)}*/}
            {/*    style={{ width: getMaxWidth(tableData.map(data => data.lotno)) }}*/}
            {/*  />*/}
            {/*</td>*/}
            {/*<td>*/}
            {/*  <input*/}
            {/*    readOnly={true}*/}
            {/*    value={row.remarks || 0}*/}
            {/*    onChange={e => handleValueChangeSelected(row.id, 'remarks', e.target.value, index)}*/}
            {/*    style={{ width: "100px" }}*/}
            {/*  />*/}
            {/*</td>*/}
            <td style={{border: 'none'}}>
              {selectedTable.type !== "toapprove" && selectedTable.type !== "approved" && (
              <button onClick={() => deleteRowSelected(index)} className="button_newrow"><i className="bi bi-dash-square"></i></button>)}
            </td>
          </tr>
          ))}
          </tbody>
        </table>

        </div>
      </div>
      )}


      {/* Нижняя таблица со средними значениями */}
      {tableDataSelected.length > 0 && (
      <div style={{display: "flex", flexDirection: "row", position: "relative"}}>
        {hideTableForChange && (<div className={hideTableForChangeTrans===true ? "white_block_for_reload" : "white_block_for_reload transparent"}>
          <div className="loader" style={{margin: "0 auto"}}></div> </div>)}
        <table className={"table_calculate"}>
          <thead>

            <tr style={{backgroundColor: '#e76f51', color: "#fff"}}>
              <th colSpan={1} style={{ textAlign: 'left', border: "0px solid black", borderBottom: "none",
                  paddingLeft: "5px"}}>target-arvot</th>
              <th>0.0</th>
              <th>1.0</th>
              <th>2.0</th>
              <th>4.0</th>
              <th>7.0</th>
              <th>12.0</th>
              <th style={{width: "77px"}}></th>
              <th>0.8</th>
              <th>0.8</th>
            </tr>

          </thead>
          <tbody>
            <tr className={"selectedtable_tr"} >
              <td colSpan={1} style={{ textAlign: 'left',
                  borderRight: "2px solid black", borderBottom: "2px solid black", paddingLeft: "5px" }}>erotus saadut - target</td>
              <td style={{ width: getMaxWidth(tableData.map(data => data.a))}}>{(parseFloat(averageValues.a.toFixed(2))).toFixed(1) || 0}</td>
              <td style={{ width: getMaxWidth(tableData.map(data => data.b))}}>{(parseFloat(averageValues.b.toFixed(2)) - 1).toFixed(1) || 0}</td>
              <td style={{ width: getMaxWidth(tableData.map(data => data.c))}}>{(parseFloat(averageValues.c.toFixed(2)) - 2).toFixed(1) || 0}</td>
              <td style={{ width: getMaxWidth(tableData.map(data => data.d))}}>{(parseFloat(averageValues.d.toFixed(2)) - 4).toFixed(1) || 0}</td>
              <td style={{ width: getMaxWidth(tableData.map(data => data.e))}}>{(parseFloat(averageValues.e.toFixed(2)) - 7).toFixed(1) || 0}</td>
              <td style={{ width: getMaxWidth(tableData.map(data => data.f))}}>{(parseFloat(averageValues.f.toFixed(2)) - 12).toFixed(1) || 0}</td>
              <td></td>
              <td style={{ width: getMaxWidth(tableData.map(data => data.c1))}}>{(parseFloat(averageValues.c1.toFixed(2)) - 0.8).toFixed(1) || 0}</td>
              <td style={{ width: getMaxWidth(tableData.map(data => data.c2))}}>{(parseFloat(averageValues.c2.toFixed(2)) - 0.8).toFixed(1) || 0}</td>
            </tr>
            <tr>
              <td style={{ width: getTotalWidth(["id",'name',"date"]),backgroundColor: "#fb8500", color: "#fff", textAlign: "left",
                  borderRight: "2px solid black", borderBottom: "none",paddingLeft: "5px" }}><strong>U/100 ml</strong></td>
              <td style={{ width: getMaxWidth(tableData.map(data => data.a)), fontWeight: "600" }}>{averageValues.a.toFixed(4) || 0}</td>
              <td style={{ width: getMaxWidth(tableData.map(data => data.b)), fontWeight: "600" }}>{averageValues.b.toFixed(4) || 0}</td>
              <td style={{ width: getMaxWidth(tableData.map(data => data.c)), fontWeight: "600" }}>{averageValues.c.toFixed(4) || 0}</td>
              <td style={{ width: getMaxWidth(tableData.map(data => data.d)), fontWeight: "600" }}>{averageValues.d.toFixed(4) || 0}</td>
              <td style={{ width: getMaxWidth(tableData.map(data => data.e)), fontWeight: "600" }}>{averageValues.e.toFixed(4) || 0}</td>
              <td style={{ width: getMaxWidth(tableData.map(data => data.f)), fontWeight: "600" }}>{averageValues.f.toFixed(4) || 0}</td>
              <td></td>
              <td style={{ width: getMaxWidth(tableData.map(data => data.c1)), fontWeight: "600" }}>{averageValues.c1.toFixed(4) || 0}</td>
              <td style={{ width: getMaxWidth(tableData.map(data => data.c2)), fontWeight: "600" }}>{averageValues.c2.toFixed(4) || 0}</td>

            </tr>
            <tr>
              <td style={{ width: '150px', backgroundColor: "#ffb703", color: "#fff",
                  textAlign: "left", fontWeight: "600", paddingLeft: "5px",
                  borderBottom: "none", borderRight: "2px solid black" }}>SD</td>
              <td style={{ width: getMaxWidth(tableData.map(data => data.a)) }}>{stdevValues.a.toFixed(4) || 0}</td>
              <td style={{ width: getMaxWidth(tableData.map(data => data.b)) }}>{stdevValues.b.toFixed(4) || 0}</td>
              <td style={{ width: getMaxWidth(tableData.map(data => data.c)) }}>{stdevValues.c.toFixed(4) || 0}</td>
              <td style={{ width: getMaxWidth(tableData.map(data => data.d)) }}>{stdevValues.d.toFixed(4) || 0}</td>
              <td style={{ width: getMaxWidth(tableData.map(data => data.e)) }}>{stdevValues.e.toFixed(4) || 0}</td>
              <td style={{ width: getMaxWidth(tableData.map(data => data.f)) }}>{stdevValues.f.toFixed(4) || 0}</td>
              <td></td>
              <td style={{ width: getMaxWidth(tableData.map(data => data.c1)) }}>{stdevValues.c1.toFixed(4) || 0}</td>
              <td style={{ width: getMaxWidth(tableData.map(data => data.c2)) }}>{stdevValues.c2.toFixed(4) || 0}</td>

            </tr>
            <tr>
              <td style={{ width: '150px', backgroundColor: "#ffc903", color: "#fff", border: "0px solid", borderBottomLeftRadius: "7px",
                borderBottom: "none", borderRight: "2px solid black", fontWeight: "600", paddingLeft: "5px", textAlign: "left" }}>CV%</td>

              <td style={{ width: getMaxWidth(tableData.map(data => data.a)), borderBottom: "none", fontSize: "11px",color:parseInt(selectedTable.cvrule.replace('%',''))<thirdRowValues.a?'white':'black',backgroundColor: parseInt(selectedTable.cvrule.replace('%',''))<thirdRowValues.a?'red':''}}>{thirdRowValues.a.toFixed(2) || 0}%</td>
              <td style={{ width: getMaxWidth(tableData.map(data => data.b)), borderBottom: "none", fontSize: "11px",color:parseInt(selectedTable.cvrule.replace('%',''))<thirdRowValues.b?'white':'black',backgroundColor: parseInt(selectedTable.cvrule.replace('%',''))<thirdRowValues.b?'red':'' }}>{thirdRowValues.b.toFixed(2) || 0}%</td>
              <td style={{ width: getMaxWidth(tableData.map(data => data.c)), borderBottom: "none", fontSize: "11px",color:parseInt(selectedTable.cvrule.replace('%',''))<thirdRowValues.c?'white':'black',backgroundColor: parseInt(selectedTable.cvrule.replace('%',''))<thirdRowValues.c?'red':'' }}>{thirdRowValues.c.toFixed(2) || 0}%</td>
              <td style={{ width: getMaxWidth(tableData.map(data => data.d)), borderBottom: "none", fontSize: "11px",color:parseInt(selectedTable.cvrule.replace('%',''))<thirdRowValues.d?'white':'black',backgroundColor: parseInt(selectedTable.cvrule.replace('%',''))<thirdRowValues.d?'red':'' }}>{thirdRowValues.d.toFixed(2) || 0}%</td>
              <td style={{ width: getMaxWidth(tableData.map(data => data.e)), borderBottom: "none", fontSize: "11px",color:parseInt(selectedTable.cvrule.replace('%',''))<thirdRowValues.e?'white':'black',backgroundColor: parseInt(selectedTable.cvrule.replace('%',''))<thirdRowValues.e?'red':'' }}>{thirdRowValues.e.toFixed(2) || 0}%</td>
              <td style={{ width: getMaxWidth(tableData.map(data => data.f)), borderBottom: "none", fontSize: "11px",color:parseInt(selectedTable.cvrule.replace('%',''))<thirdRowValues.f?'white':'black',backgroundColor: parseInt(selectedTable.cvrule.replace('%',''))<thirdRowValues.f?'red':'' }}>{thirdRowValues.f.toFixed(2) || 0}%</td>
              <td style={{borderBottom: "none"}}></td>
              <td style={{ width: getMaxWidth(tableData.map(data => data.c1)), borderBottom: "none", fontSize: "11px",color:parseInt(selectedTable.cvrule.replace('%',''))<thirdRowValues.c1?'white':'black',backgroundColor: parseInt(selectedTable.cvrule.replace('%',''))<thirdRowValues.c1?'red':'' }}>{thirdRowValues.c1.toFixed(2) || 0}%</td>
              <td style={{ width: getMaxWidth(tableData.map(data => data.c2)), borderBottom: "none", fontSize: "11px",color:parseInt(selectedTable.cvrule.replace('%',''))<thirdRowValues.c2?'white':'black',backgroundColor: parseInt(selectedTable.cvrule.replace('%',''))<thirdRowValues.c2?'red':'' }}>{thirdRowValues.c2.toFixed(2) || 0}%</td>
            </tr>
            {/*<tr style={{fontSize: "13px"}}>*/}
            {/*  <th colSpan={1} style={{ textAlign: 'left', fontWeight: "100" }}>n=</th>*/}
            {/*  <th colSpan={6}>*/}
            {/*      <input*/}
            {/*        value={tableDataSelected.length || 0}*/}
            {/*        style={{ width: getMaxWidth(tableData.map(data => data.a)), textAlign: 'center' }}*/}
            {/*      />*/}
            {/*  </th>*/}
            {/*  <th></th>*/}
            {/*  <th colSpan={2}>*/}
            {/*      <input*/}
            {/*        value={tableDataSelected.length || 0}*/}
            {/*        style={{ width: getMaxWidth(tableData.map(data => data.a)), textAlign: 'center' }}*/}
            {/*      />*/}
            {/*  </th>*/}
            {/*</tr>*/}
          </tbody>
        </table>


        {/* Range Table */}
        <table style={{marginBottom: "auto"}}>
          <thead style={{marginLeft: "0px", border: "2px solid "}}>
            <tr><th colSpan={4} style={{fontSize: "14px"}}>Range Table</th></tr>
          </thead>
          <tbody>
            <tr style={{backgroundColor: "#ccffda"}}>
              <td style={{width: "70px", fontWeight: "600", fontSize: "13px"}}>kontrollit  </td>
              <td style={{fontSize: "13px"}}>-{selectedTable.rangetable}</td>
              <td>
                  <input
                    value={(parseFloat(averageValues.c1.toFixed(1))*0.7).toFixed(1) || 0}
                    style={{ width: getMaxWidth(tableData.map(data => data.c1))}}
                  />
              </td>
              <td>
                  <input
                    value={(parseFloat(averageValues.c2.toFixed(1))*0.7).toFixed(1) || 0}
                    style={{ width: getMaxWidth(tableData.map(data => data.c2))}}
                  />
              </td>
            </tr>
            <tr style={{backgroundColor: "#ccffda"}}>
              <td style={{width: "70px", fontWeight: "600", fontSize: "13px"}}>rajat:</td>
              <td style={{fontSize: "13px"}}>{selectedTable.rangetable}</td>
              <td>
                <input
                  value={(parseFloat(stdevValues.c1.toFixed(1))*0.7).toFixed(1) || 0}
                  style={{ width: getMaxWidth(tableData.map(data => data.c1)) }}
                />
              </td>
              <td>
                <input
                  value={(parseFloat(stdevValues.c2.toFixed(1))*1.3).toFixed(1) || 0}
                  style={{ width: getMaxWidth(tableData.map(data => data.c2)) }}
                />
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      )}

      {((selectedTable.type==="toapprove") || (selectedTable.type==="approved")) && (
      <form onSubmit={handleCreateNewStamp} style={{position: "relative"}}>
        {hideTableForChange && (<div className={hideTableForChangeTrans===true ? "white_block_for_reload" : "white_block_for_reload transparent"}>
          <div className="loader" style={{margin: "0 auto"}}></div> </div>)}

        <div style={{display: "flex", flexDirection: "column", width: "fit-content", marginTop: "20px"}}>
          <div style={{display: "flex", flexDirection: "row", width: "fit-content"}}>
            <h3 style={{margin: "0 auto"}}>Approve</h3>
            {selectedTable.type==="approved" && (
                <button type="button" className={"button_submitapprove"}><i className="bi bi-check-circle-fill" style={{color: "#5b82b9"}}></i></button>)}
            {selectedTable.type !== "approved" && (
                <button type="submit" className={"button_submitapprove"}><i className="bi bi-check-circle"></i></button>)}
          </div>
          {/* Approval Stamp Table */}
          <table style={{margin: "5px 0"}}>
            <tbody>
              <tr>
                <td style={{backgroundColor: selectedTable.type==="approved" ? "#93b7eb" : "lightgray", width: "50px",
                    fontWeight: "600", borderBottom: "none", paddingLeft: "5px"}}>Author</td>
                <td>
                  <input
                    readOnly={selectedTable.type==="approved" ? true : false}
                    name="stampauthor"
                    // value={selectedTable.type==="toapprove" ? formNewStamp.stampauthor : selectedTable.stampauthor}
                    value={selectedTable.type == "approved" ? selectedTable.stampauthor : username.charAt(0).toUpperCase() + username.slice(1)}
                    style={{width: "auto"}}
                    onChange={handleChangeNewStamp}
                  />
                </td>
              </tr>
              <tr>
                <td style={{backgroundColor: selectedTable.type==="approved" ? "#93b7eb" : "lightgray",
                    fontWeight: "600", borderBottom: "none", paddingLeft: "5px"}}>Details</td>
                <td>
                  <input
                    readOnly={selectedTable.type==="approved" ? true : false}
                    name="stamptext"
                    // value={selectedTable.type==="toapprove" ? selectedTable.type : selectedTable.stamptext}
                    value={selectedTable.type==="toapprove" ? formNewStamp.stamptext : selectedTable.stamptext}
                    style={{width: "auto"}}
                    onChange={handleChangeNewStamp}
                  />
                </td>
              </tr>
              <tr>
                <td style={{backgroundColor: selectedTable.type==="approved" ? "#93b7eb" : "lightgray",
                    fontWeight: "600", borderBottom: "none", paddingLeft: "5px"}}>Date</td>
                <td>
                  <input
                    readOnly={selectedTable.type==="approved" ? true : false}
                    name="stamptime"
                    // value={selectedTable.type==="toapprove" ? formNewStamp.stamptime : selectedTable.stamptime}
                    value={selectedTable.type == "approved" ? selectedTable.stamptime : currentDate}
                    // value={currentDate}
                    style={{width: "auto"}}
                    onChange={handleChangeNewStamp}
                    // style={{width: "70px"}}
                  />
                </td>
              </tr>
            </tbody>
          </table>

        </div>
      </form>
      )}

    </div>
  );
};


const App: React.FC = () => {
  // Example
  // const data: Person[] = [
  //   { id: 1, name: 'Alice', date: '1990-01-01', a: 1, b: 3, c: 2, d: 1, e: 6 },
  //   { id: 2, name: 'Bob', date: '1995-05-05', a: 2, b: 2, c: 3, d: 2, e: 24 },
  //   { id: 3, name: 'Carl', date: '1995-05-05', a: 2, b: 2, c: 3, d: 2, e: 24 },
  //   { id: 4, name: 'Devid', date: '1995-05-05', a: 2, b: 2, c: 3, d: 2, e: 24 },
  // ];

  return (
    <div>
      <link rel="stylesheet" href="https://cdn.jsdelivr.net/npm/bootstrap-icons@1.11.3/font/bootstrap-icons.min.css" />
      <Table />
    </div>
  );
};

export default App;
